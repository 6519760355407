// Small font sizes
$font-small: 16px;
$font-xsmall: 14px;
$font-xxsmall: 12px;

// Breakpoints
$bp-xl: min-width 1600px;
$bp-lg: min-width 1200px;
$bp-md: min-width 900px;
$bp-sm: min-width 600px;
$bp-xsm: max-width 600px;

// Global spacing
$space-col: 24px; // spacing to match columns in containers
$space-xs: 10px;
$space-sm: 20px;
$space-md: 30px;
$space-lg: 40px;
$space-xl: 80px;

//Media quries
$bp-xs: 'min-width: 576px';
$bp-sm: 'min-width: 768px';
$bp-md: 'min-width: 992px';
$bp-lg: 'min-width: 1200px';
$bp-xl: 'min-width: 1440px';
$bp-xxl: 'max-width: 1680px';
$bp-xxxl: 'max-width: 1680px';


//Colors
$c-red: #B42729;
$c-dark-red: #A30F11;
$c-pure-black: #000000;
$c-light-grey: #F3F3F3;
$c-dark-grey: #444444;


/*********************** Menu  **************************/
@media($bp-sm) {
  .navigation .level0 .submenu a:hover, .navigation .level0 .submenu a.ui-state-focus {
    background: white;
    color: $c-red;
  }
}

/*********************** Menu **************************/




/*********************** Home Page **************************/

.filterproduct-title {
  .content {
    font-family: 'Oswald';
    color: #444444;
    font-size: 25px;
  }
}

.brands-slide {
  margin-bottom: 50px;
}

.about-us-block {
  margin: 100px 0;

  &__left-block {
    background: #000000;
    color: #ffffff;
    padding: 60px 80px !important;

    &--heading {
      h2 {
        font-family: 'Oswald';
        font-size: 40px;
        font-weight: 400;
      }
    }

    &--content {
      width: 60%;

      p {
        font-size: 1.4rem;
      }
    }
  }

  &__right-block {
    position: relative;

    &--about-us-img {
      /*position: absolute;
      bottom: 0;
      left: -100px;
      min-width: 120%;
      @media($bp-md) {
        position: static;
      }*/
    }
  }
}



/* .about-us-block{
   margin: 100px 0;
 }

 .about-us-block .left-block{
   background: #000000;
   color: #ffffff;
   padding: 60px 80px;
 }
 .about-us-block .left-block .block-heading h2{
   font-family: 'Oswald';
   font-size: 40px;
   font-weight: 400;
 }
 .about-us-block .left-block .block-content{
   width: 60%;
 }
 .about-us-block .left-block .block-content p{
   font-size: 1.4rem;
 }

 .about-us-block .right-block{
   position: relative;
 }

 .about-us-block .right-block img.about-us-img{
   position: absolute;
   bottom: 0;
   left: -100px;
   min-width: 120%
 }
*/
.homepage-banner {
  min-height: 450px;
  height: calc(100vh - 400px);
}


